body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.game {
  max-width: 600px;
  margin: 5vh auto;
}

.game__info {
  text-align: center;
}

.game__header {
  font-size: 2em;
}

.game__description {
  margin: 2em 0;
  line-height: 1.5em;
  text-align: justify;
}

.game__settings {
  margin: 0 0 3vh 0;
  display: flex;
  flex-wrap: wrap;
}

.game__mode {
  display: flex;
  height: 100px;
  width: 100%;
  border: 1px solid #2b2d42;
  margin-bottom: 10px;
  padding: 10px;
  background: #131e3b;
  cursor: pointer;
}

.game__mode:hover {
  background: #344470;
}

.mode__text {
  width: 100%;
  text-align: left;
  padding-left: 20px;
  color: #fafafa;
}

.mode__item {
  font-size: 20px;
  min-width: 110px;
  position: relative;
  font-weight: bold;
  color: #fff;
  top: calc(50% - 12px);
  left: 0;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .game {
    max-width: 600px;
    margin: 5vh auto;
  }
}