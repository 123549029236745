.think-logic {
  background: #fff;
  margin: 0;
}

.think-logic__header {
  display: flex;
  height: 70px;
  justify-content: space-between;
  background: #2b2d42;
}

.think-logic__container {
  display: flex;
  justify-content: space-between;
  color: #fafafa;
  padding: 0 20px;
}

.think-logic__title {
  display: flex;
  align-items: center;
  font-size: 22px;
}

.think-logic__commands {
  display: flex;
  justify-content: center;
}

.think-logic__item {
  margin: auto 10px;
  cursor: pointer;
}

.think-logic__text {
  display: flex;
}

.think-logic__text:hover {
  color: #a9d6e5;
}

.think-logic__timer {
  text-align: center;
  font-size: 6vmin;
  font-weight: 700;
  margin: auto;
  line-height: 6vmin;
  color: #fafafa;
}

.think-logic__timer.danger {
  animation: blinker 1s linear infinite;
}

/* Timer */

#timer > div {
  text-align: center;
  font-size: 6vmin;
  font-weight: 700;
  line-height: 8vmin;
  padding: 0 3vmin;
}

#timer > div.danger {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
      color: #ff6450;
      text-shadow: 0 0 10px #ff6450;
  }
}

#gameOver {
  padding: 2vmin;
  font-size: 3vmin;
  font-weight: 700;
}

#gameOver.black {
  background: #212529;
  color: #aaa;
}

#info {
  flex: 0 1 auto;
  display: flex;
  background-color: #5D8EE9
}

#info-data {
  flex: 4 0 0;
}

#info-data > div {
  padding: 2vmin;
  font-size: 3vmin;
  font-weight: 700;
}

.game-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

#playground {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.board {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.word-card {
  flex: 1;
  flex-direction: column;
  display: flex;
  min-width: 20%;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 0.5px;
  font-size: 4vmin;
  word-break: break-word;
  align-self: stretch;
  user-select: none;
}

.board > .word-card > div {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  position: relative;
}

.card-box {
  background: #fafafa;
  color: #444;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-box.red.opened {
  background: repeating-linear-gradient(
          135deg,
          #bc6060,
          #bc6060 10px,
          #984646 10px,
          #984646 20px
  );
}

.card-box.blue.opened {
  background: repeating-linear-gradient(
          135deg,
          #606dbc,
          #606dbc 10px,
          #465298 10px,
          #465298 20px
  );
}

.card-box.neutral.opened {
  background: repeating-linear-gradient(
          135deg,
          #ffecca,
          #ffecca 10px,
          #ffdcb5 10px,
          #ffdcb5 20px
  );
}

.card-box.black.opened {
  background: repeating-linear-gradient(
          135deg,
          #4c4c4c,
          #4c4c4c 10px,
          #282828 10px,
          #282828 20px
  );
}

.card-box:hover {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
}

.card-box > span {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: normal;
}

.status {
  position: relative;
}

.status__info {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2b2d42;
}

.status__turn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 1.8;
  color: #fafafa;
}

.status-menu {
  display: flex;
  transition: all 0.3s ease-out;
}

.status .status-pane {
  flex: 1 1 0;
}

.status-pane__timer {
  text-align: center;
  font-size: 6vmin;
  font-weight: 700;
  line-height: 8vmin;
}

/* Board */

.red, .red h1 {
  background: #DD504B;
  color: #6a040f;
  font-weight: 700;
}

.blue, .blue h1 {
  background: #5D8EE9;
  color: #03045e;
  font-weight: 700;
}

.black, .black h1 {
  background: #212529;
  color: #e5e5e5;
}

.neutral, .neutral h1 {
  background: #fef9ef;
  color: #bebebe;
}

.team-count {
  text-align: center;
  font-size: 15vmin;
  line-height: 13vmin;
  margin: 0;
}

@media screen and (orientation: portrait) {
  body {
      height: 120vh;
  }
  #playground {
      transform: rotate(90deg);
      transform-origin: top left;
      width: 100vh;
      height: 100vw;
      left: 100vw;
  }
}

@media (min-width: 1600px) {
  .board {
      padding: 12px;
  }

  .board > .word-card {
      font-size: 4vmin;
      padding: 12px;
  }
}

@media (min-width: 960px) and (max-width: 1599px) {
  .board {
      padding: 12px;
  }

  .board > .word-card {
      font-size: 4vmin;
      padding: 12px;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .board {
      padding: 8px;
  }

  .board > .word-card {
      padding: 8px;
  }

  .think-logic__header {
      height: 40px;   
  }

  .think-logic__title {
      font-size: 0;
  }

  .think-logic__text {
      font-size: 0;
  }

  .status__info {
      display: none;
  }

  .team-count {
      font-size: 10vmin;
  }
}

@media (min-width: 490px) and (max-width: 767px) {
  .board {
      padding: 6px;
  }

  .board > .word-card {
      padding: 6px;
  }
  
  .think-logic__header {
    height: 40px;   
  }

  .think-logic__title {
      font-size: 0;
  }

  .think-logic__text {
      font-size: 0;
  }

  .status__info {
      display: none;
  }

  .team-count {
      font-size: 10vmin;
  }
}

@media (max-width: 489px) {
  .board {
      padding: 4px;
  }

  .board > .word-card {
      padding: 4px;
  }
  
  .think-logic__header {
    height: 40px;
  }

  .think-logic__title {
      font-size: 0;
  }

  .think-logic__text {
      font-size: 0;
  }

  .status__info {
      display: none;
  }

  .team-count {
      font-size: 10vmin;
  }
}

@media (max-width: 630px) and (orientation: portrait), (max-height: 630px) and (orientation: landscape) {
}