.long-click-notification {
  position: absolute;
  z-index: 200000;
  top: 5px;
  bottom: 5px;
  background: rgba(0, 0, 0, 0.85);
  width: 65%;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  font-size: 4vmin;
  padding: 20px 50px;
  text-align: center;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
