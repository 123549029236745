.container {
  position: relative;
  width: 1200px;
  height: 300px;
  margin: 240px auto;
  cursor: pointer;
}

.container .box {
  position: relative;
  width: calc(400px - 30px);
  height: calc(300px - 30px);
  background: #000;
  float: left;
  margin: 15px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}

/* Box */

.container .box .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 1;
}

.container .box:hover .icon {
  top: 20px;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.container .box .icon .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  transition: 0.5s;
  color: #fff;
}

.container .box:hover .icon .fa {
  font-size: 40px;
}

/* Content */

.container .box .content {
  position: absolute;
  top: 100%;
  height: calc(100% - 100px);
  width: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.5s;
  opacity: 0;
}

.container .box:hover .content {
  top: 100px;
  opacity: 1;
}

.container .box .content h3 {
  margin: 0 0 10px;
  padding: 0;
  color: #fff;
  font-size: 24px;
}

.container .box .content p {
  margin: 0;
  padding: 0;
  color: #ffffff;
}

.container .box:nth-child(1) .icon {
  background: #319635;
}

.container .box:nth-child(1) {
  background: #4caf50;
}

.container .box:nth-child(2) .icon {
  background: #d03852;
}

.container .box:nth-child(2) {
  background: #f54967;
}

.container .box:nth-child(3) .icon {
  background: #23798e;
}

.container .box:nth-child(3) {
  background: #328fa5;
}